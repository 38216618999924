<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Wallet Management Portal</h1>
      <div class="flex items-end justify-end my-5">
        <button
          @click="
            $router.push({
              path: 'wallet-crediting-portal-select-business',
              query: {
                debit: false,
              },
            })
          "
          class="block uppercase shadow ml-2 text-xs bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-5 float-right rounded"
        >
          Credit Wallet
        </button>
        <button
          @click="
            $router.push({
              path: 'wallet-crediting-portal-select-business',
              query: {
                debit: true,
              },
            })
          "
          class="block uppercase shadow ml-2 text-xs bg-red-500 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-5 float-right rounded"
        >
          Debit Wallet
        </button>
      </div>
      <div class="mt-14" style="min-width: 600px">
        <table class="shadow-lg w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business ID
              </th>
              <th
                class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Request Date
              </th>
              <th
                class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Traction Rep Name
              </th>
              <th
                class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Approved Date
              </th>
              <th
                class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Amount
              </th>
              <th
                class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Request Type
              </th>
              <th
                class="text-black text-sm border whitespace-normal text-left px-8 py-2"
              >
                Narrations
              </th>
              <th
                class="text-black text-sm border whitespace-normal text-left px-8 py-2"
              >
                Comments
              </th>
              <th
                class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Status
              </th>
              <th
                class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(wallet, i) in filterWallets" :key="i">
              <td
                class="border px-8 py-3 break-words whitespace-normal text-xs"
              >
                {{ (wallet.business_id && wallet.business_id) || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-normal text-xs">
                {{ (wallet.business_name && wallet.business_name) || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-normal text-xs">
                {{
                  moment(wallet.initiated_created_at).format(
                    "DD MMM YYYY - h:mm:ss a"
                  )
                }}
              </td>
              <td class="border px-8 py-3 whitespace-normal text-xs">
                {{ wallet.initiated_by.name || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-normal text-xs">
                {{
                  wallet.authorized_created_at === "0001-01-01T00:00:00Z"
                    ? ""
                    : moment(wallet.authorized_created_at).format(
                        "DD MMM YYYY - h:mm:ss a"
                      )
                }}
              </td>
              <td class="border px-8 py-3 whitespace-normal text-xs">
                {{ wallet.amount_credited || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-normal text-xs">
                {{ wallet.wallet_request_type || "" }}
              </td>
              <td
                class="border px-8 whitespace-normal py-3 text-xs"
                style="
                  word-break: break-word;
                  word-wrap: break-word;
                  word-spacing: unset;
                  white-space: normal;
                "
              >
                {{ wallet.narration || "" }}
              </td>
              <td
                class="border px-8 whitespace-normal py-3 text-xs"
                style="
                  word-break: break-word;
                  word-wrap: break-word;
                  word-spacing: unset;
                  white-space: normal;
                "
              >
                {{ wallet.comment || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-normal text-xs">
                {{
                  (wallet.approval_status &&
                    wallet.approval_status.toUpperCase()) ||
                    "N?A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-normal text-xs">
                <button
                  @click="handleApproveClick(wallet)"
                  class="block uppercase shadow ml-2 text-xs bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none p-2 float-right rounded"
                  v-if="
                    wallet.approval_status &&
                      wallet.approval_status.toLowerCase() === 'pending'
                  "
                >
                  Approve Request
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
          <h4>Approve request?</h4>
          <div class="flex justify-start">
            <input
              type="radio"
              id="approveRequest"
              v-model="approveRequest"
              class="mr-3"
              value="approved"
            />
            &nbsp;<label for="approveRequest">Approve</label>
          </div>
          <div class="flex justify-start">
            <input
              type="radio"
              id="rejectRequest"
              v-model="approveRequest"
              class="mr-3"
              value="declined"
            />
            &nbsp;<label for="rejectRequest">Decline</label>
          </div>
          <div class="flex justify-start">
            <textarea
              rows="3"
              class="block ring-1 text-xs rounded p-3 w-full"
              v-model="comment"
              placeholder="Comments"
            ></textarea>
          </div>
          <div
            class="flex justify-start text-xs px-0 text-red-600"
            v-if="this.errorMssg !== ''"
          >
            {{ this.errorMssg }}
          </div>
          <div>
            <button
              @click="approveCredit"
              class="uppercase shadow mx-auto hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold p-2 rounded float-right"
            >
              Submit
            </button>
            <button
              @click="handleCloseAlertDiv"
              class="float-right uppercase shadow mx-2 hover:bg-red-400 bg-red-600 focus:shadow-outline focus:outline-none text-white text-xs font-bold p-2 rounded"
              style="width: inherit"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="promptDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-check2-circle text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
            />
            <path
              d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
            />
          </svg>
          <p class="text-sm">Thank you, the request has been updated.</p>
          <button
            @click="promptDiv = false"
            class="float-right uppercase shadow mx-auto hover:bg-red-500 bg-red-600 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import executiveUsers from "../config/execUsers";
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      alertDiv: false,
      approveRequest: "",
      rejectRequest: null,
      selectedWallet: null,
      comment: "",
      errorMssg: "",
      promptDiv: false,
      approval_status: "",
    };
  },
  computed: {
    ...mapGetters(["GET_WALLET_CREDITING"]),
    filterWallets() {
      return this.GET_WALLET_CREDITING.data !== null ||
        this.GET_WALLET_CREDITING.data !== ""
        ? this.GET_WALLET_CREDITING.data.items
        : [];
    },
    check_auth() {
      const userEmail = GET_USER_BUSINESS_DATA().email;
      return executiveUsers.includes(userEmail);
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // this.isLoading = true;
  },
  async created() {
    this.fetchRecords();
  },
  methods: {
    async fetchRecords() {
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch("FETCH_WALLET_CREDITING");
        if (response.status) {
          this.isLoading = false;
        } else {
          alert("Sorry, You're not authorized to view this page.");
        }
      } catch (error) {}
      this.isLoading = false;
    },
    handleCloseAlertDiv() {
      this.alertDiv = false;
      this.approveRequest = "";
      this.comment = "";
    },
    async approveCredit() {
      if (this.approveRequest === "declined" && this.comment === "") {
        this.errorMssg = "Comment is required.";
      } else {
        if (this.approveRequest !== "") {
          if (this.selectedWallet.wallet_request_type === "DEBIT") {
            this.isLoading = true;

            const payload = {
              request_id: this.selectedWallet._id,

              business_email: this.selectedWallet.business_email,

              comment: this.comment,

              approval_status: this.approveRequest.toUpperCase(),
            };

            try {
              let response = await this.$store.dispatch(
                "APPROVE_WALLET_DEBIT_REQUEST",

                payload
              );

              if (response.status) {
                this.promptDiv = true;

                this.fetchRecords();

                this.comment = "";

                this.alertDiv = false;

                this.approveRequest = "";
              } else {
                alert(response.message);
              }
            } catch (error) {}

            this.isLoading = false;

            this.errorMssg = "";

            this.comment = "";
          } else {
            this.isLoading = true;

            const payload = {
              request_id: this.selectedWallet._id,

              business_email: this.selectedWallet.business_email,

              comment: this.comment,

              approval_status: this.approveRequest.toUpperCase(),
            };

            try {
              let response = await this.$store.dispatch(
                "APPROVE_WALLET_CREDITING_REQUEST",

                payload
              );

              if (response.status) {
                this.promptDiv = true;

                this.fetchRecords();

                this.comment = "";

                this.alertDiv = false;

                this.approveRequest = "";
              } else {
                alert(response.message);
              }
            } catch (error) {}

            this.isLoading = false;

            this.errorMssg = "";

            this.comment = "";
          }
        } else {
          this.errorMssg = "You have to select approve or decline";
          // this.comment = "";
        }
      }
    },
    handleApproveClick(wallet) {
      this.selectedWallet = wallet;
      this.alertDiv = true;
      this.comment = "";
      this.errorMssg = "";
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(222, 230, 242) none repeat scroll 0% 0%;
  border-top: 1px solid rgb(0, 9, 61);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
