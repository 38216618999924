const executiveUsers = [
  "mayowalli@yahoo.co.uk",
  "demilade@tractionapps.co",
  "tobi@tractionventurepartners.co",
  "isabel@tractionapps.co",
  "dkuyoro@hotmail.co.uk",
  "aosinaike@protonmail.com",
  "tflux2011@gmail.com",
  "kayode@tractionapps.co",
  "dolapo@tractionapps.co",
  "qd@tractionapps.co",
  "abosede@tractionapps.co",
  "mayowa@tractionapps.co"
];
export default executiveUsers;
